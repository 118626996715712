import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import makeSlug from 'lodash.kebabcase';

const BlogPostCard = ({ post }) => (
  <article key={post.fields.slug} className="blog-card">
    <Link to={post.fields.slug}>
      <img className="blog-card-img" alt="" src={post.frontmatter.imageUrl} />
    </Link>
    <div className="blog-card-content">
      {post.frontmatter.categories.map(category => (
        <Link
          key={category}
          className="blog-card-category-link"
          to={`/blog/categories/${makeSlug(category)}`}
        >
          <h4>{category}</h4>
        </Link>
      ))}
      <div className="blog-card-metadata">
        <span>{post.frontmatter.date}</span>
        <span className="blog-divider" />
        <span>{post.timeToRead} { post.timeToRead === 1 ? 'min' : 'mins'}</span>
        { post.frontmatter.author.frontmatter.name && (
        <Fragment>
          <span className="blog-divider" />
          <span>{post.frontmatter.author.frontmatter.name}</span>
        </Fragment>
        )}
      </div>
      <h3><Link to={post.fields.slug}>{post.frontmatter.title}</Link></h3>
      <p className="blog-card-excerpt">{post.excerpt}</p>
    </div>
  </article>
);

BlogPostCard.propTypes = {
  post: PropTypes.shape({
    excerpt: PropTypes.string.isRequired,
    timeToRead: PropTypes.number.isRequired,
    fields: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }).isRequired,
    frontmatter: PropTypes.shape({
      imageUrl: PropTypes.string.isRequired,
      categories: PropTypes.array.isRequired,
      date: PropTypes.string.isRequired,
      author: PropTypes.shape({
        frontmatter: PropTypes.shape({
          name: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }).isRequired,

  }).isRequired,
};

export default BlogPostCard;
