import React from 'react';
import className from 'classnames';
import PropTypes from 'prop-types';

const FAQCard = ({ question, answer, extendedClassName }) => (
  <div className={className('faq-card-container', extendedClassName)}>
    <h3 className="faq-card-question">{question}</h3>
    <p className="faq-card-answer">{answer}</p>
  </div>
);

FAQCard.defaultProps = {
  extendedClassName: '',
};

FAQCard.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  extendedClassName: PropTypes.string,
};

export default FAQCard;
