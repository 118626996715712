import React from 'react';
import { Container, Row } from 'reactstrap';
import FAQCard from './faq-card';
import { FAQS_ID } from '../../constants';

const data = [
  {
    question: 'Who is Knapsac for',
    answer: 'Any adult with a UK bank account who wants to take a step towards achieving their life goals. Perhaps you want a holiday, a motorbike, a nicer place to live, a career change, to pay off debt or just to save some rainy day money.',
  }, {
    question: 'How will you track my spending and income?',
    answer: 'Assuming you give us permission, we shall use the Open Banking environment to access securely encrypted bank data to track your spending and income.',
  }, {
    question: 'How do you handle my personal data?',
    answer: 'We respect your privacy and personal information. We will therefore only use the information that you agree to share with us to further develop our service.',
  }, {
    question: 'How secure will the Knapsac app be?',
    answer: 'Your connection to Knapsac will always be encrypted. This means that all your digital information can only be read by our server or your computer.',
  }, {
    question: 'What is Open Banking?',
    answer: 'In the UK, the Competition and Markets Authority (CMA) is forcing the largest current account providers to open up their data. This means developers of mobile and web applications will be able to \'plug in\' to your current account data in a secure and standardised way, if you give them permission to do so. The aim is to encourage innovation and improve competition, by making it easier for you to access multiple accounts and compare or switch financial products.',
  }, {
    question: 'How will you make money?',
    answer: 'We have number of choices. We could charge a monthly subscription. We could charge a fee for a premium service. We may offer suggestions for products and services that will help you achieve your goal quicker and will save you money. If you select a product based on our suggestion, we may receive a fee from the product provider. We have not yet decided the best way to make money for us or for our users. Watch this space for updates.',
  }, {
    question: 'Do you or will you have a mobile or web app available?',
    answer: 'We are in the early stages of developing our app. Sign up to be kept in touch with our progress.',
  },
];

const FAQs = () => (
  <Row id={FAQS_ID} className="faqs-row">
    <Container>
      <Row>
        <h2 className="faqs-title">FAQs</h2>
        <div className="faq-cards-container">
          {
            data.map(item => (
              <FAQCard
                question={item.question}
                answer={item.answer}
                extendedClassName={item.extendedClassName}
                key={item.question}
              />
            ))
          }
        </div>
        <img className="faq-stars" src="/img/stars.svg" alt="stars" />
      </Row>
    </Container>
  </Row>
);

export default FAQs;
