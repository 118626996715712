import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const GoalCard = ({
  title,
  description,
  Image,
  imageOnLeft,
  extendedClassName,
}) => (
  <div className={classNames('goal-card-container', extendedClassName)}>
    <div className="col-lg-7 col-md-6">
      <h3 className="goal-card-title">{title}</h3>
      <p className="goal-card-description">{description}</p>
    </div>
    <div className={classNames('goal-card-image-container col-lg-5 col-md-6', extendedClassName, { 'image-on-left': imageOnLeft })}>
      <Image />
    </div>
  </div>
);

GoalCard.defaultProps = {
  imageOnLeft: false,
  extendedClassName: '',
};

GoalCard.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  Image: PropTypes.func.isRequired,
  imageOnLeft: PropTypes.bool,
  extendedClassName: PropTypes.string,
};

export default GoalCard;
