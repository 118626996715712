import React from 'react';
import PropTypes from 'prop-types';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import {
  FAQS_ID,
  DOWNLOAD_APP_ID,
  facebookGroup,
} from '../../constants';


const title = 'Reach your savings target faster';
const description = '<p>A simple overview and a friendly nudge when your spending needs to change. Earn rewards as you achieve your monthly savings targets!</p>';

export const HomePageIntroduction = () => {
  let hasFiredEvent = false;

  const onGetInvolvedPressed = (location) => {
    if (!hasFiredEvent) {
      trackCustomEvent({
        category: 'GetInvolvedButtonPressed',
        action: location,
        label: 'home-page',
      });
      hasFiredEvent = true;
    }
  };

  return (
    <React.Fragment>
      <section className="intro-section">
        <img className="target-image" src="/img/target-icon.png" alt="Arrow hitting a target" srcSet="/img/target-icon.png, /img/target-icon@2x.png 2x /img/target-icon@3x.png 3x" />
        <h2>Goal achieved!</h2>
        <h3>You discovered Knapsac</h3>
        <img
          src="img/header-asset.png"
          srcSet="img/header-asset@2x.png 2x,
             img/header-asset@3x.png 3x"
          className="intro-image"
          alt="Mobile phone with knapsac app displayed"
        />
      </section>
      <section className="intro-section-desktop">
        <div className="section-content">
          <div className="left-intro-content">
            <h2>{title}</h2>
            <div className="home-intro" dangerouslySetInnerHTML={{ __html: description }} />
          </div>
          <div className="right-intro-content">
            <img
              src="img/desktop-header.png"
              srcSet="img/desktop-header@2x.png 2x,
             img/desktop-header@3x.png 3x"
              className="intro-image"
              alt="Mobile phone with knapsac app displayed"
            />
          </div>
        </div>
      </section>
      <section className="target-section">
        <h2>{title}</h2>
        <div className="home-intro" dangerouslySetInnerHTML={{ __html: description }} />
      </section>
      <section className="try-section">
        <div className="section-content">
          <img
            src="img/illustration-4-png-01-1.png"
            srcSet="img/illustration-4-png-01-1@2x.png 2x,
        img/illustration-4-png-01-1@3x.png 3x"
            className="intro-image"
            alt="Mobile phone with knapsac app displayed"
          />
          <div className="content-box">
            <p className="title">Try Knapsac</p>
            <p>
              If you’re keen to get started with Knapsac then why not join the Knapsac Early Access Programme? Launching in early 2021, you’ll be one of the first to start achieving your savings goals with Knapsac!
            </p>
            <a href={facebookGroup} onClick={() => onGetInvolvedPressed('get-involved-top')}>
              <div className="try-button">Get Involved</div>
            </a>
          </div>
        </div>
      </section>
      <section className="dream-section">
        <div className="section-content">
          <div>
            <h3>How Knapsac Works</h3>
            <h2>What do you dream about?</h2>
            <p>Tell Knapsac what you want to save for and it'll help you set a timeframe.</p>
          </div>
          <img
            src="img/feature-1.png"
            srcSet="img/feature-1@2x.png 2x,
             img/feature-1@3x.png 3x"
            className="intro-image"
            alt="Mobile phone with knapsac app displayed"
          />
        </div>
      </section>
      <section className="secure-section">
        <div className="section-content">
          <div>
            <h2>Securely connect your accounts</h2>
            <p>Using OpenBanking technology, securely connect all of your bank accounts &amp; credit cards. We operate to the same high standards of security as your bank.</p>
          </div>
          <img
            src="img/feature-2.png"
            srcSet="img/feature-2@2x.png 2x,
             img/feature-2@3x.png 3x"
            className="intro-image"
            alt="Mobile phone with knapsac app displayed"
          />
        </div>
      </section>
      <section className="clarity-section">
        <div className="section-content">
          <div>
            <h2>Clarity in seconds</h2>
            <p>Every day, Knapsac automatically calculates exactly how much you can spend to hit your monthly target.</p>
          </div>
          <img
            src="img/feature-3.png"
            srcSet="img/feature-3@2x.png 2x,
             img/feature-3@3x.png 3x"
            className="intro-image"
            alt="Mobile phone with knapsac app displayed"
          />
        </div>
      </section>
      <section className="unlock-section">
        <div className="section-content">
          <div>
            <h2>Unlock rewards</h2>
            <p>Complete tasks that save you money and unlock rewards!</p>
          </div>
          <img
            src="img/feature-4.png"
            srcSet="img/feature-4@2x.png 2x,
             img/feature-4@3x.png 3x"
            className="intro-image"
            alt="Mobile phone with knapsac app displayed"
          />
        </div>
      </section>
      <section className="try-section-2">
        <div className="section-content">
          <div className="left-content">
            <p className="title">Try Knapsac</p>
            <p>
              If you’re keen to get started with Knapsac then why not join the Knapsac Early Access Programme? Launching in early 2021, you’ll be one of the first to start achieving your savings goals with Knapsac!
            </p>
            <a href={facebookGroup} onClick={() => onGetInvolvedPressed('get-involved-middle')}>
              <div className="try-button">Get Involved</div>
            </a>
          </div>
          <div className="right-content">
            <p className="stay-informed title">Stay Informed</p>
            <form
              className="form-inline email-capture"
              name="email-signup"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field-hero"
              action="/welcome"
            >
              <input
                name="email"
                type="email"
                required
                placeholder="Email"
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"
              />
              <input type="hidden" name="bot-field-hero" />
              <input type="hidden" name="form-name" value="email-signup" />
              <button type="submit" className="try-button">Join the Wait List</button>
            </form>

          </div>
        </div>
      </section>
      <section className="faq-section">
        <h2 id={FAQS_ID}>FAQs</h2>
        <div className="section-content">
          <ul>
            <li>
              <p className="question">Is it secure?</p>
              <p>Yes. Knapsac is powered by Open Banking technology and we operate to the same high standards of security as your bank. Your connection to Knapsac will always be encrypted.</p>
            </li>
            <li>
              <p className="question">What is Open Banking?</p>
              <p>In the UK, the Competition and Markets Authority (CMA) is forcing the largest current account providers to open up their data. This means developers of mobile and web applications will be able to &quot;plug in&quot; to your current account data in a secure and standardised way, if you give them permission to do so. The aim is to encourage innovation and improve competition, by making it easier for you to access multiple accounts and compare or switch financial products.</p>
            </li>
            <li>
              <p className="question">How can I use Knapsac?</p>
              <p>For early access you can join the<a href={facebookGroup} onClick={() => onGetInvolvedPressed('get-involved-bottom')}> Knapsac Early Access Programme</a>, launching in early 2021. This beta version of Knapsac will work through a secure website rather than an iPhone or Android app. It will still be accessible on your smartphone and the security measures are the same.
              </p>
            </li>
            <li>
              <p className="question">Is it free?</p>
              <p>Yes.</p>
            </li>
            <li>
              <p className="question">Am I guaranteed a place on the Knapsac Early Access me?</p>
              <p>We’re limiting the beta to a small group of users so please get involved as early as possible!</p>
            </li>
            <li>
              <p className="question">How long will the Knapsac Early Access me last?</p>
              <p>We expect this to last 1 – 2 months. In this time, we may roll out changes based on everyone’s feedback. Once the testing phase is complete, we’ll return to building the iOS app and active beta participants will be the first to access this.</p>
            </li>
          </ul>
        </div>
      </section>
    </React.Fragment>

  );
};


export const DownloadPageIntroduction = ({ onDownloadClicked }) => (
  <div className="download-page-container">
    <h1 className="download-page-title">{title}</h1>
    <p className="download-page-intro">
      {description}
    </p>
    <a onClick={onDownloadClicked} href={`#${DOWNLOAD_APP_ID}`}>
      <img className="download-page-img hide-on-desktop" alt="Download on the App Store" src="/img/download-apple-store-red.svg" />
      <img className="download-page-img show-on-desktop" alt="Download on the App Store" src="/img/download-apple-store-white.svg" />
    </a>
  </div>
);

DownloadPageIntroduction.propTypes = {
  onDownloadClicked: PropTypes.func.isRequired,
};
