import React from 'react';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';
import MoneyFanImage from '../images/money-fan';
import { DOWNLOAD_APP_ID } from '../../../constants';
import SignUpForm from '../../signup-form';
import {
  moneyFanTitle as desktopMoneyFanTitle,
  moneyFanDescription,
} from '../common';

const mobileMoneyFanTitle = 'It\'s all about goals';

const MoneyFanCard = () => (
  <div className="download-card-container">
    <div>
      <h3 className="download-blurb-card-title hide-on-desktop">{mobileMoneyFanTitle}</h3>
      <h3 className="download-blurb-card-title show-on-desktop">{desktopMoneyFanTitle}</h3>
      <p className="download-blurb-card-description">{moneyFanDescription}</p>
    </div>
    <div className="download-card-image-container">
      <MoneyFanImage />
    </div>
  </div>
);

const Download = ({ onClick }) => (
  <div className="download-container">
    <h2 id={DOWNLOAD_APP_ID} className="download-card-secondary-title">Get started</h2>
    <input
      onClick={onClick}
      type="image"
      className="download-blurb-img"
      alt="Download on the App Store"
      src="/img/download-apple-store-white.svg"
    />
  </div>
);

Download.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const SignUp = () => (
  <div className="download-container download-sign-up-container">
    <h2 id={DOWNLOAD_APP_ID} className="download-card-secondary-title">Available soon</h2>
    <p className="download-blurb-description">
      {'App store access available soon. We\'re currently tweaking and testing.'}
      <p>Provide your email below to be notified when we launch.</p>
    </p>
    <SignUpForm />
  </div>
);

const DownloadBlurb = ({ downloadClicked, onDownloadClicked }) => (
  <Row className="download-wavey-section-container">
    <MoneyFanCard />
    {
      downloadClicked
        ? <SignUp />
        : <Download onClick={onDownloadClicked} />
    }
  </Row>
);

DownloadBlurb.propTypes = {
  downloadClicked: PropTypes.bool.isRequired,
  onDownloadClicked: PropTypes.func.isRequired,
};

export default DownloadBlurb;
