import React from 'react';
import PropTypes from 'prop-types';
import BlogPostCard from '../blog-post-card';

const BlogPosts = ({ posts }) => (
  <div className="blog-cards-container">
    {posts.edges.map(({ node }) => (
      <BlogPostCard key={node.fields.slug} post={node} />
    ))}
  </div>
);

BlogPosts.propTypes = {
  posts: PropTypes.shape({
    edges: PropTypes.array.isRequired,
  }).isRequired,
};

export default BlogPosts;
