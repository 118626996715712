import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from 'reactstrap';

const SWIPE_THRESHOLD = 50;

const items = [
  {
    title: 'Tell us what you\'re aiming for and we\'ll help you get there',
    imagePath: 'on-track',
    altText: 'Slide 1',
  },
  {
    title: 'Simple and fun – complete tasks to increase your level and achieve your financial goals',
    imagePath: 'space-rocket',
    altText: 'Slide 2',
  },
  {
    title: 'Avoid the details – we analyse your activity and provide simple, actionable insights',
    imagePath: 'set-backs',
    altText: 'Slide 3',
  },
  {
    title: 'Secure using state-of-the-art, bank-level security measures to protect you',
    imagePath: 'create-pin',
    altText: 'Slide 4',
  },
];

const AppCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [touchStartX, setTouchStartX] = useState();

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const handleTouchStart = e => setTouchStartX(e.changedTouches[0].screenX);

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].screenX;
    const swipeDistance = touchEndX - touchStartX;

    if (Math.abs(swipeDistance) < SWIPE_THRESHOLD) {
      return;
    }

    if (touchEndX < touchStartX) {
      next();
    } else {
      previous();
    }
  };

  const slides = items.map((item) => {
    const imgSrc = `/img/carousel/${item.imagePath}`;
    const desktopImgSrc = `${imgSrc}/desktop`;
    const mobileImgSrc = `${imgSrc}/mobile`;

    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.imagePath}
      >
        <picture>
          <source
            media="(min-width: 768px)"
            srcSet={`${desktopImgSrc}/1x.jpg 323w, ${desktopImgSrc}/2x.jpg 646w, ${desktopImgSrc}/3x.jpg 969w`}
            sizes="323px"
          />
          <source
            srcSet={`${mobileImgSrc}/1x.jpg 323w, ${mobileImgSrc}/2x.jpg 646w, ${mobileImgSrc}/3x.jpg 969w`}
            sizes="268px"
          />
          <img src={`${imgSrc}/mobile/3x.jpg`} alt={item.altText} />
        </picture>
      </CarouselItem>
    );
  });

  return (
    <div
      className="carousel-container"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <h3 className="carousel-item-title">{items[activeIndex].title}</h3>
      <Carousel
        className="carousel-parent-div"
        activeIndex={activeIndex}
        next={next}
        previous={previous}
      >
        {slides}
        <CarouselIndicators className="carousel-indics" items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      </Carousel>
    </div>


  );
};

export default AppCarousel;
