import React from 'react';
import PropTypes from 'prop-types';
import classname from 'classnames';

const Card = ({
  title,
  description,
  Image,
  extendedClassName,
  imageOnLeft,
}) => (
  <div className="homepage-blurb-card-container">
    <div>
      <h3 className="homepage-blurb-card-title">{title}</h3>
      <p className="homepage-blurb-card-description">{description}</p>
    </div>
    <div className={classname('homepage-blurb-image-container', extendedClassName, { 'image-on-left': imageOnLeft })}>
      <Image />
    </div>
  </div>
);

Card.defaultProps = {
  extendedClassName: '',
  imageOnLeft: false,
};

Card.propTypes = {
  title: PropTypes.node.isRequired,
  description: PropTypes.string.isRequired,
  Image: PropTypes.func.isRequired,
  extendedClassName: PropTypes.string,
  imageOnLeft: PropTypes.bool,
};

export default Card;
