import React, { Fragment } from 'react';
import { Row } from 'reactstrap';
import GoalCard from './goal-card';
import PersonReading from './goals/person-reading';
import FamilySkiing from './goals/family-skiing';
import DebtTank from './goals/debt-tank';

const highlightText = string => <span className="orange-text">{string}</span>;

const personReadingTitle = <Fragment>Your money. Your goals. Simplified</Fragment>;
const familySkiingTitle = <Fragment>Link your bank accounts easily and safely</Fragment>;
const debtTankTitle = <Fragment>Become the champion of your money. Earn rewards.</Fragment>;

const personReadingDescription = (
  <Fragment>
    <p>
      <b>Intelligent automated budgets</b>
      <br />
      Knapsac will get you budgeting in seconds, with our intelligent automated budgets you will be able to set savings goals for anything
    </p>
    <p>
      <b>Forecasting tool</b>
      <br />
      Specify how long you want to save for and Knapsac will show you if it's possible and build your budget
    </p>
    <p>
      <b>Real rewards</b>
      <br />
      Rather than offering you generic rewards, the Knapsac app will offer you tailored rewards that are directly related to your savings target
    </p>
  </Fragment>
);
const familySkiingDescription = (
  <Fragment>
    You can quickly and easily link any number of bank accounts, savings accounts and credit cards. This enables us to build intelligent budgets for you based around your savings goals. Knapsac uses the most up to date encryption so that account access is completely secure.
  </Fragment>
);

const debtTankDescription = (
  <Fragment>
    Everyone starts Knapsac as a Novice, but with discounts, rewards and other tempting treats available as you progress through the ranks, Knapsac encourages you to take more interest in your money. <br /><br /> Think of it as ‘two birds with one stone’ – your money management improves and you earn rewards.
  </Fragment>
);

const CustomerGoalsSection = () => (
  <Row>
    {/* <div className="hr-container">
      <div className="hr-left" />
      <h2 className="hr-title"> Knapsac is currently building personal budgeting software for those who want to:</h2>
      <div className="hr-right" />
    </div> */}
    <div className="goal-cards-container">
      <GoalCard
        title={personReadingTitle}
        description={personReadingDescription}
        Image={PersonReading}
        extendedClassName=""
      />
      <GoalCard
        title={familySkiingTitle}
        description={familySkiingDescription}
        Image={FamilySkiing}
        extendedClassName=""
        imageOnLeft
      />
      <GoalCard
        title={debtTankTitle}
        description={debtTankDescription}
        Image={DebtTank}
        extendedClassName=""
      />
    </div>
  </Row>
);

export default CustomerGoalsSection;
