import React from 'react';

const MONEY_FAN = '/img/money-fan-image/';

export default () => (
  <img
    srcSet={`${MONEY_FAN}@1x.png 598w, ${MONEY_FAN}@2x.png 1196w,${MONEY_FAN}@3x.png 1794w`}
    src={`${MONEY_FAN}@3x.png`}
    sizes="(max-width: 767px) 273px, (max-width: 1231px) 392px, 598px"
    alt="fan blowing money"
  />
);
