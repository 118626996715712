import React, { Fragment } from 'react';
import { Row } from 'reactstrap';
import Card from './card';
import PeopleShoppingImage from '../images/people-shopping';
import MoneyFanImage from '../images/money-fan';
import {
  moneyFanTitle,
  moneyFanDescription,
  highlightText,
} from '../common';
import { JOIN_US_ID } from '../../../constants';
import SignUpForm from '../../signup-form';

const peopleShoppingTitle = <Fragment>It&apos;s {highlightText('okay')}<br />to be bad</Fragment>;
const peopleShoppingDescription = 'Let\'s be honest: it\'s tough to create a budget and stick to it. It\'s a drag. So we are building an app that makes it fun and easy for you to budget.';

const HomePageBlurb = () => (
  <Row className="homepage-wavey-section-container">
    {/* <h2 className="homepage-blurb-title">We believe:</h2>
    <Card
      title={peopleShoppingTitle}
      description={peopleShoppingDescription}
      Image={PeopleShoppingImage}
      imageOnLeft
      extendedClassName="people-shopping"
    />
    <Card
      title={moneyFanTitle}
      description={moneyFanDescription}
      Image={MoneyFanImage}
      extendedClassName="money-fan"
    /> */}
    <h2 id={JOIN_US_ID} className="homepage-card-secondary-title">Get early access</h2>
    <div className="homepage-sign-up-form-container">
      <SignUpForm />
    </div>
    <p className="homepage-join-us-description">Enter your email and we&apos;ll let you know when the app is available</p>
  </Row>
);

export default HomePageBlurb;
