import React from 'react';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import { Input } from 'reactstrap';

const SignUpForm = () => {
  let hasFiredEvent = false;

  const userHasTyped = () => {
    if (!hasFiredEvent) {
      trackCustomEvent({
        category: 'UserEnterEmail',
        action: 'typed',
        label: 'landing-page-1',
      });
      hasFiredEvent = true;
    }
  };

  return (
    <form
      name="email-signup"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action="/welcome"
    >
      <div className="sign-up-actions-container">
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="email-signup" />
        <Input
          className="homepage-join-us-input"
          name="email"
          type="email"
          placeholder="E-mail"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$"
          onChange={userHasTyped}
          required
        />
        <Input
          type="submit"
          value="Sign up"
          className="homepage-join-us-sign-up-btn"
        />
      </div>
    </form>
  );
};

export default SignUpForm;
